import React from "react";
import { Form, Icon, Input, Button } from "antd";
import { API_ROOT } from "../constants";
import { Redirect } from "react-router-dom";

const FormItem = Form.Item;

class NormalLoginForm extends React.Component {
  state = {
    redirect: false,
    messageInfo: null,
    isError: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        fetch(`${API_ROOT()}/user/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            loginId: values.username,
            password: values.password
          })
        })
          .then(response => {
            if (response.ok) {
              return response.text();
            }
            throw new Error(response.statusText);
          })
          .then(data => {
            var Data = JSON.parse(data);
            if (Data.isChurchAdmin === 1) {
              this.setState({
                isError: false,
                messageInfo: "Login Success. Welcome To CBC"
              });
              setTimeout(
                function() {
                  if (Data.isInitial === 1) {// first login
                    this.setState({ redirect: true });
                    // this.props.history.push('/resetPassword');
                  }
                  this.props.handleLogin(Data);
                }.bind(this),
                1500
              );
            } else {
              throw new Error("Not a admin user");
            }
          })
          .catch(e => {
            console.log(e);
            this.setState({
              isError: true,
              messageInfo: "Login Failed, Please Check And Try Again"
            });
          });
      }
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/resetPassword" />;
    }
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <FormItem>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
          <p
            style={
              this.state.isError ? { color: "#FF0000" } : { color: "#389e0d" }
            }
          >
            {this.state.messageInfo}
          </p>
        </FormItem>
      </Form>
    );
  }
}

export const Login = Form.create()(NormalLoginForm);
