import React from "react";
import { Modal, Button } from "antd";
import { UploadForm } from "./UploadForm";
import { API_ROOT, AUTH_HEADER, TOKEN_KEY } from "../constants";

export class UploadButton extends React.Component {
  state = {
    visible: false,
    confirmLoading: false
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        const token = localStorage.getItem(TOKEN_KEY);
        const formData = new FormData();
        formData.set(
          "title",
          encodeURIComponent(this.props.endDate.format("LL")) + ".docx"
        );
        formData.set("file", values.file[0].originFileObj);
        this.setState({ confirmLoading: true });
        fetch(`${API_ROOT()}/uploadFile`, {
          method: "POST",
          headers: {
            Authorization: `${AUTH_HEADER} ${token}`
          },
          body: formData
        })
          .then(response => {
            if (response.ok) {
              this.props.handleUpload();
              return;
            }
            throw new Error("Failed to upload.");
          })
          .then(() => {
            this.setState({ visible: false, confirmLoading: false });
            this.form.resetFields();
          })
          .catch(e => {
            console.error(e);
            this.props.handleUploadFail();
            this.setState({ confirmLoading: false });
          });
      }
    });
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState({
      visible: false
    });
  };

  getFormRef = formInstance => {
    this.form = formInstance;
  };

  render() {
    const { visible, confirmLoading } = this.state;
    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          Upload
        </Button>
        <Modal
          title="Select a file to upload"
          visible={visible}
          onOk={this.handleOk}
          okText="Apply"
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <UploadForm ref={this.getFormRef} />
        </Modal>
      </div>
    );
  }
}
