import React from "react";
import "antd/dist/antd.css";
import { Icon, Typography } from "antd";

const { Title } = Typography;

class TopBar extends React.Component {
  render() {
    return (
      <div>
        <Title className="App-title">Crossroad Bible Church</Title>
        <header className="App-header">
          {/* <div className="App-title">Crossroad Bible Church</div> */}
          {this.props.isLoggedIn ? (
            // eslint-disable-next-line
            <a onClick={this.props.handleLogout} className="logout">
              <Icon type="logout" /> Logout
            </a>
          ) : null}
        </header>
      </div>
    );
  }
}

export default TopBar;
