import React from "react";
import "antd/dist/antd.css";
import { Typography } from "antd";

const { Text } = Typography;

export class Messages extends React.Component {

  getMessage() {
    let message = "";
    if (this.props.isPublishError) {
      message = "Publish failed. Please check and try Again.";
    } else if (this.props.isUploadError) {
      message = "Upload failed. Please check and try Again.";
    } else if (this.props.isUploaded) {
      if (this.props.isPublished) {
        message =
          "Publish completed. To make update, click the button on the right to upload a new file.";
      } else if (this.props.isReviewing) {
        message =
          "Reviewing. Click 'Update' to change the content, or 'Publish' to release the content after reviewed.";
      } else if (this.props.isParsing) {
        message =
          "Parsing. Click 'Update' to change the content, or 'Publish' to release the content after reviewed.";
      } else {
        message =
          "Upload completed. Click 'Update' to change the content, or 'Publish' to release the content after reviewed.";
      }
    } else {
      message =
        "Click the button on the right to upload your daily questions from a file.";
    }
    return message;
  }

  render() {
    return <Text className="messages"> {this.getMessage()} </Text>;
  }
}
