import { API_ROOT, AUTH_HEADER, TOKEN_KEY } from "../constants";
import { Button, DatePicker, Form, Typography } from "antd";

import { Messages } from "./Messages";
import React from "react";
import { Status } from "./Status";
import { UploadButton } from "./UploadButton";
import moment from "moment";

const { Title, Text } = Typography;
const { WeekPicker } = DatePicker;

class Home extends React.Component {
  state = {
    startDate: moment().day(1),
    endDate: moment().day(7),
    isUploaded: false,
    isUploadError: false,
    isParsing: false,
    isReviewing: false,
    isPublished: false,
    isPublishError: false,
    isLoggedIn: true,
    status: null
  };

  componentDidMount() {
    this.getFileStatus(this.state.endDate);
    this.getStatus();
  }

  handleWeekChange = weekData => {
    this.setState({
      startDate: moment(weekData).day(1),
      endDate: moment(weekData).day(7)
    });
    return this.getFileStatus(moment(weekData).day(7));
  };

  handleUpload = () => {
    this.setState({
      isUploaded: true,
      isUploadError: false,
      isParsing: false,
      isReviewing: false,
      isPublished: false,
      isPublishError: false
    });
  };

  handleUploadFail = () => {
    this.setState({
      isUploaded: false,
      isUploadError: true,
      isParsing: false,
      isReviewing: false,
      isPublished: false,
      isPublishError: false
    });
  };

  handlePublish = () => {
    const token = localStorage.getItem(TOKEN_KEY);
    fetch(
      `${API_ROOT()}/publish/` +
      encodeURIComponent(this.state.endDate.format("LL")),
      {
        method: "GET",
        headers: {
          Authorization: `${AUTH_HEADER} ${token}`
        }
      }
    )
      .then(response => {
        if (response.ok) {
          this.setState({
            isParsing: false,
            isReviewing: false,
            isPublished: true,
            isPublishError: false
          });
          return;
        }
        throw new Error("this is an error");
      })
      .catch(err => {
        console.error(err);
        this.setState({
          isParsing: false,
          isReviewing: true,
          isPublished: false,
          isPublishError: true
        });
      });
  };

  getFileStatus(endDate) {
    const token = localStorage.getItem(TOKEN_KEY);

    fetch(
      `${API_ROOT()}/DocumentStatus/` +
      encodeURIComponent(endDate.format("LL")),
      {
        method: "GET",
        headers: {
          Authorization: `${AUTH_HEADER} ${token}`,
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          localStorage.removeItem(TOKEN_KEY);
          this.setState({ isLoggedIn: false });
          throw new Error("The access token is expired. Please log in again!");
        } else {
          throw new Error("Something went wrong.");
        }
      })
      .then(data => {
        this.handleFileStateChange(data);
      })
      .catch(err => {
        console.error(err);
        alert(err);
      });
  }

  handleFileStateChange(data) {
    if (data.inreview === 1) {
      this.setState({
        isUploaded: true,
        isParsing: false,
        isReviewing: true,
        isPublished: false
      });
    } else if (data.uploadedWaitForParsing === 1) {
      this.setState({
        isUploaded: true,
        isParsing: true,
        isReviewing: false,
        isPublished: false
      });
    } else if (data.published === 1) {
      this.setState({
        isUploaded: true,
        isParsing: false,
        isReviewing: false,
        isPublished: true
      });
    } else if (data.NoUpload === 1) {
      this.setState({
        isUploaded: false,
        isParsing: false,
        isReviewing: false,
        isPublished: false
      });
    }
  }

  getStatus() {
    const token = localStorage.getItem(TOKEN_KEY);
    fetch(
      `${API_ROOT()}/status`, {
      method: "GET",
      headers: {
        Authorization: `${AUTH_HEADER} ${token}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          localStorage.removeItem(TOKEN_KEY);
          this.setState({ isLoggedIn: false });
          throw new Error("The access token is expired. Please log in again!");
        } else {
          throw new Error("Something went wrong.");
        }
      })
      .then(data => {
        this.setState({ status: data })
      })
      .catch(err => {
        console.error(err);
        alert(err);
      });
  }

  render() {
    const messageColor = {
      backgroundColor: this.state.isUploadError || this.state.isPublishError ? "#FF0000"
        : this.state.isUploaded ? this.state.isPublished ? "#03D897" : "#04F7AD"
          : null
    };

    if (!this.state.isLoggedIn) {
      setTimeout(() => document.location.reload(true), 3000);
    }

    return (
      <div>
        <Form layout="inline" className="week">
          <Title level={3}>Study Guide for the week of</Title>
          <b />
          <Form.Item>
            <Text className="data">
              {this.state.startDate.format("MMM Do")} To{" "}
              {this.state.endDate.format("MMM Do YYYY")}
            </Text>
          </Form.Item>
          <Form.Item>
            <WeekPicker
              size="large"
              placeholder="Select Week"
              defaultValue={moment()}
              onChange={this.handleWeekChange}
            />
          </Form.Item>
        </Form>
        <Form layout="inline" className="questions">
          <Title level={3}>Daily Study Questions</Title>
          <b />
          <Form.Item style={messageColor}>
            {/* <Text className="messages"> {this.getMessage()} </Text> */}
            <Messages
              isPublishError={this.state.isPublishError}
              isUploadError={this.state.isUploadError}
              isUploaded={this.state.isUploaded}
              isPublished={this.state.isPublished}
              isReviewing={this.state.isReviewing}
              isParsing={this.state.isParsing}
            />
          </Form.Item>
          <Form.Item>
            <UploadButton
              endDate={this.state.endDate}
              handleUpload={this.handleUpload}
              handleUploadFail={this.handleUploadFail}
            />
          </Form.Item>
          <Form.Item>
            {this.state.isUploaded ? (
              <Button type="primary" onClick={this.handlePublish}>
                Publish
              </Button>
            ) : null}
          </Form.Item>
        </Form>
        {this.state.status &&
          <Status data={this.state.status} />
        }
      </div>
    );
  }
}

export default Home;
