import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { Redirect } from "react-router-dom";
import { API_ROOT, AUTH_HEADER, TEMP_KEY } from "../constants";

const { Text } = Typography;
const FormItem = Form.Item;

class ResetForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    redirect: false,
    messageInfo: null,
    isError: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const token = localStorage.getItem(TEMP_KEY);
        fetch(`${API_ROOT()}/user/updateProfile`, {
          method: "PUT",
          headers: {
            Authorization: `${AUTH_HEADER} ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            password: values.password,
            isInitial: 1
          })
        })
          .then(response => {
            if (response.ok) {
              return response.text();
            }
            throw new Error(response.statusText);
          })
          .then(data => {
            this.setState({
              isError: false,
              messageInfo:
                "Reset Successed, This Window Will Redirect To Login Page"
            });
            setTimeout(
              function() {
                this.setState({ redirect: true });
                // this.props.history.push('/login');
              }.bind(this),
              1500
            );
          })
          .catch(e => {
            console.log(e);
            this.setState({
              isError: true,
              messageInfo:
                "Reset Failed (The Length of Password is At Least 6), Please Double Check "
            });
          });
      }
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <Form onSubmit={this.handleSubmit} className="reset">
        <FormItem className="info">
          <Text>Please enter your own password</Text>
        </FormItem>
        <FormItem {...formItemLayout} label="Password">
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "Please input your password!"
              },
              {
                validator: this.validateToNextPassword
              }
            ]
          })(<Input type="password" />)}
        </FormItem>
        <FormItem {...formItemLayout} label="Confirm Password">
          {getFieldDecorator("confirm", {
            rules: [
              {
                required: true,
                message: "Please confirm your password!"
              },
              {
                validator: this.compareToFirstPassword
              }
            ]
          })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Reset
          </Button>
          <p
            style={
              this.state.isError ? { color: "#FF0000" } : { color: "#389e0d" }
            }
          >
            {this.state.messageInfo}
          </p>
        </FormItem>
      </Form>
    );
  }
}

export const ResetPassword = Form.create()(ResetForm);
