import { Col, Row } from 'antd';

import Chart from 'react-apexcharts';
import React from 'react';

export class Status extends React.Component {
    constructor(props) {
        super(props);

        console.log(props.data);

        let dateDAU = []
        let countDAU = [];
        const dau = props.data.dau;
        if (Array.isArray(dau)) {
            dateDAU = dau.map(item => {
                const date = new Date(item.Date);
                return `${date.getMonth() + 1}/${date.getDate()}`;
            }).reverse();
            countDAU = dau.map(item => item.DeviceCount).reverse();
        }

        let dateMAU = []
        let countMAU = [];
        const mau = props.data.mau;
        if (Array.isArray(mau)) {
            dateMAU = mau.map(item => {
                const date = new Date(item.date);
                return `${date.getMonth() + 1}/${date.getDate()}`;
            }).reverse();
            countMAU = mau.map(item => item.count).reverse();
        }

        let platformLabels = []
        let platformCount = [];
        const platform = props.data.platform;
        if (Array.isArray(platform)) {
            platformLabels = platform.map(item => item.platformOS);
            platformCount = platform.map(item => item.count);
        }

        let dateNew = []
        let countNew = [];
        const newDevice = props.data.newDevice;
        if (Array.isArray(newDevice)) {
            dateNew = newDevice.map(item => {
                const date = new Date(item.date);
                return `${date.getMonth() + 1}/${date.getDate()}`;
            }).reverse();
            countNew = newDevice.map(item => item.count).reverse();
        }
        console.log({ newDevice });

        this.state = {
            optionsDAU: {
                chart: {
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Daily active users',
                    align: 'center'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: dateDAU,
                }
            },
            seriesDAU: [{
                name: "Count",
                data: countDAU
            }],
            optionsMAU: {
                dataLabels: {
                    enabled: false
                },
                title: {
                    text: 'Monthly active users',
                    align: "center"
                },
                xaxis: {
                    categories: dateMAU
                }
            },
            seriesMAU: [{
                name: 'Monthly active user',
                data: countMAU
            }],
            optionsNew: {
                chart: {
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'New device',
                    align: 'center'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: dateNew,
                }
            },
            seriesNew: [{
                name: "Count",
                data: countNew
            }],
            optionsPlatform: {
                title: {
                    text: 'Platform',
                    align: "center"
                },
                labels: platformLabels,
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    showAlways: true,
                                    show: true
                                }
                            }
                        }
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom'
                }
            },
            seriesPlatform: platformCount
        }
    }

    render() {
        const marginTop = 80;
        return (
            <div id="chart" align="center" style={{ paddingTop: 20 }}>
                <Row type="flex" justify="center">
                    <Col span={8}>
                        <div style={{ margin: 0, marginTop }}>
                            <Chart options={this.state.optionsDAU} series={this.state.seriesDAU} type="line" />
                        </div>
                    </Col>
                    <Col span={1} />
                    <Col span={8}>
                        <div style={{ margin: 0, marginTop }}>
                            <Chart options={this.state.optionsMAU} series={this.state.seriesMAU} type="bar" />
                        </div>
                    </Col>
                </Row>
                <Row type="flex" justify="center">
                    <Col span={8}>
                        <div style={{ margin: 0, marginTop }}>
                            <Chart options={this.state.optionsNew} series={this.state.seriesNew} type="line" />
                        </div>
                    </Col>
                    <Col span={1} />
                    <Col span={8} >
                        <div style={{ padding: 80 }}>
                            <Chart options={this.state.optionsPlatform} series={this.state.seriesPlatform} type="donut" />
                        </div>
                    </Col>
                </Row>
            </div >
        );
    }
}