import React from "react";
import { Form, Upload, Icon } from "antd";

class NormalUploadForm extends React.Component {
  normFile = e => {
    let fileType = e.fileList[e.fileList.length - 1].name.split(".").pop();
    if (fileType !== "docx") {
      return;
    }
    if (e.fileList.length === 2) {
      e.fileList.shift();
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  beforeUpload = () => false;

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };
    return (
      <Form {...formItemLayout}>
        <Form.Item label="file">
          <div className="dropbox">
            {getFieldDecorator("file", {
              valuePropName: "fileList",
              getValueFromEvent: this.normFile,
              rules: [
                { required: true, message: 'Please select a ".docx" file!' }
              ]
            })(
              <Upload.Dragger
                multiple={false}
                accept="docx"
                name="files"
                beforeUpload={this.beforeUpload}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Upload.Dragger>
            )}
          </div>
        </Form.Item>
      </Form>
    );
  }
}

export const UploadForm = Form.create()(NormalUploadForm);
