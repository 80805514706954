import React from "react";
import { ResetPassword } from "./ResetPassword";
import { Login } from "./Login";
import Home from "./Home";
import { Switch, Route, Redirect } from "react-router-dom";
class Main extends React.Component {
  getHome = () => {
    return this.props.isLoggedIn ? <Home /> : <Redirect to="/login" />;
  };

  getLogin = () => {
    return this.props.isLoggedIn ? (
      <Redirect to="/cbc" />
    ) : (
      <Login handleLogin={this.props.handleLogin} />
    );
  };

  render() {
    return (
      <div className="main">
        <Switch>
          <Route exact path="/" render={this.getLogin} />
          <Route exact path="/cbc" render={this.getHome} />
          <Route exact path="/login" render={this.getLogin} />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <Route render={this.getLogin} />
        </Switch>
      </div>
    );
  }
}

export default Main;
