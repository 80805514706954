import React, { Component } from "react";
import TopBar from "./TopBar";
import Main from "./Main";
import { message } from "antd";
import { API_ROOT, AUTH_HEADER, TOKEN_KEY, TEMP_KEY } from "../constants";
import "../styles/App.css";

class App extends Component {
  state = {
    isLoggedIn: Boolean(localStorage.getItem(TOKEN_KEY))
  };

  handleLogin = data => {
    var token = data.accessToken;
    var currentLoginTime = new Date();
    // check wether need to refresh token
    var tmp = localStorage.getItem("loginTime");
    var lastLoginTime = new Date(tmp);
    if (
      tmp !== null &&
      lastLoginTime.getDate() - currentLoginTime.getDate() > 7
    ) {
      fetch(`${API_ROOT()}/user/refreshAccessToken`, {
        method: "POST",
        headers: {
          Authorization: `${AUTH_HEADER} ${token}`
        },
        body: null
      })
        .then(response => {
          if (response.ok) {
            return response.text();
          }
          throw new Error(response.statusText);
        })
        .then(data => {
          message.success("Refresh Token Success");
          token = data.accessToken;
        })
        .catch(e => {
          console.log(e);
          message.error("Refresh Token Failed.");
        });
    }
    if (data.isInitial === 1) {
      localStorage.setItem(TEMP_KEY, token);
    } else {
      localStorage.setItem("loginTime", currentLoginTime);
      localStorage.setItem(TOKEN_KEY, token);
      this.setState({ isLoggedIn: true });
    }
    
  };

  handleLogout = () => {
    localStorage.removeItem(TOKEN_KEY);
    this.setState({ isLoggedIn: false });
  };

  render() {
    return (
      <div className="App">
        <TopBar
          isLoggedIn={this.state.isLoggedIn}
          handleLogout={this.handleLogout}
        />
        <Main
          isLoggedIn={this.state.isLoggedIn}
          handleLogin={this.handleLogin}
          handleLogout={this.handleLogout}
        />
      </div>
    );
  }
}

export default App;
